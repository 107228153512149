// import Swiper styles

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./../scss/app.scss";
// import "./magnific-popup/core";
// import "./../scss/magnific-popup.css";
// import Swiper JS
import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";

import AjaxLoadMore_module from "./ajaxLoadMore";
window.AjaxLoadMore = AjaxLoadMore_module;
import "bootstrap";

(function ($) {
  "use strict";
  var init = false;
  var featuredSwiper;
  function featuredSwiper() {
    if (window.innerWidth <= 573) {
      if (!init) {
        init = true;
        // Featured Section Slider
        featuredSwiper = new Swiper(".featured-swiper", {
          loop: false,
          slidesPerView: 2.4,
          spaceBetween: 0,
        });
      }
    } else if (init) {
      featuredSwiper.forEach((element) => {
        element.destroy();
      });
      init = false;
    }
  }
  featuredSwiper();
  window.addEventListener("resize", featuredSwiper);

  const swiperMobile = new Swiper(".swiper-mobile-screens", {
    modules: [Navigation, Pagination],
    loop: false,
    slidesPerView: 4,
    spaceBetween: 20,
    // Navigation arrows
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    //Pagination
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      bulletClass: "swiper-bullet",
      bulletActiveClass: "is-active",
    },
    breakpoints: {
      // when window width is >= 320px
      0: {
        slidesPerView: 2.2,
        spaceBetween: 20,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 3.2,
        spaceBetween: 20,
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
    },
  });

  const swiperDesktop = new Swiper(".swiper-desktop-screens", {
    loop: false,
    slidesPerView: 2,
    modules: [Navigation, Pagination],
    spaceBetween: 20,
    // Navigation arrows
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    //Pagination
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      bulletClass: "swiper-bullet",
      bulletActiveClass: "is-active",
    },
    breakpoints: {
      // when window width is >= 640px
      0: {
        slidesPerView: 1.1,
        spaceBetween: 20,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
    },
  });

  $(document).ready(function () {
    //PopUp Windows
    function popupWindow(url, title, w, h) {
      var win = window.parent;
      var y = win.top.outerHeight / 2 + win.top.screenY - h / 2;
      var x = win.top.outerWidth / 2 + win.top.screenX - w / 2;
      return win.open(
        url,
        title,
        "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
          w +
          ", height=" +
          h +
          ", top=" +
          y +
          ", left=" +
          x
      );
    }

    $(".popupWindow").on("click", function (event) {
      var url = $(this).attr("href");
      popupWindow(url, "Share", 600, 600);

      event.preventDefault();
      return false;
    });
  });
})(jQuery);
