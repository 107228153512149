import axios from "axios";

class AjaxLoadMoreNews {
  isObj(a) {
    if ((!!a) && (a.constructor === Object)) {
      return true;
    }
    return false;
  };

  _st(z, g) {
    return "" + (g !== "" ? "[" : "") + z + (g !== "" ? "]" : "");
  };

  objectToUrlParams(params, skipobjects, prefix) {
    if (skipobjects === void 0) {
      skipobjects = false;
    }
    if (prefix === void 0) {
      prefix = "";
    }
    var result = "";
    if (typeof(params) != "object") {
      return prefix + "=" + encodeURIComponent(params) + "&";
    }
    for (var param in params) {
      var c = "" + prefix + this._st(param, prefix);
      if (this.isObj(params[param]) && !skipobjects) {
        result += this.objectToUrlParams(params[param], false, "" + c);
      } else if (Array.isArray(params[param]) && !skipobjects) {
        params[param].forEach((item, ind) => {
          result += this.objectToUrlParams(item, false, c + "[" + ind + "]");
        });
      } else {
        result += c + "=" + encodeURIComponent(params[param]) + "&";
      }
    }
    return result;
  };


  async getPosts(settings) {
    let params = {
      "site_url" : settings.site_url ?? "",
      "action": "load_more_posts",
      "current_page": settings.current_page,
      "total_pages": settings.total_pages,
      "template_directory": settings.template_directory,
      "wrapper": settings.wrapper,
      ...settings
    };

    // Get arguments out of args array
    if (settings.args) {
      for (const args_key in settings.args) {
        //Check if is object
        params[args_key] = settings.args[args_key];
      }
    }
    delete params.args;


    let payload = this.objectToUrlParams(params);
    let prepared_params = new URLSearchParams('?' + payload);

    return new Promise((resolve, reject) => {
      axios.post(params.site_url + '/wp-admin/admin-ajax.php', prepared_params)
        .then(({data}) => {
          if (data.success === true) {
            resolve(data.data);
          } else {
            reject(data.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  }
}

export default AjaxLoadMoreNews;
